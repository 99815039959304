@import "/src/colors.scss";

.logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.company-name-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1.25rem;
}

.company-name-first-part {
    color: $primary;
    margin: 0.1rem;
    font-size: 1.75rem;
    font-weight: 700;
}

.company-name-second-part {
    color: $primary;
    margin: 0.1rem;
    font-size: 1.305rem;
    font-weight: 400;
}
