@import "/src/colors.scss";

.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    color: $primary;
}

.line {
    height: 2px;
    width: 100%;
    background-color: $primary;
}

.line-thin {
    height: 1px;
    width: 100%;
    background-color: $primary;
}

.title {
    font-size: 2.6rem;
    font-weight: 700;
    margin-left: 3rem;
    margin-right: 3rem;
}