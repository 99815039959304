@import "/src/colors.scss";

.page-wrapper {
    display: flex;
    flex-direction: column;
}

.container {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 20px);
    max-width: 1400px;
}
