@import "/src/colors.scss";

.main-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10rem;
    color: $primary;
}

.left-column, .right-column {
    display: flex;
    align-items: center;
    justify-content: center;
}

.left-column {
    flex-direction: column;
    align-items: start;
    width: 40%;
    min-width: 372px;
}

.right-column {
    width: 60%;
    justify-content: end;
}

.main-section h1, .main-section h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 6px;
}

.main-section h1 {
    font-size: 2.6rem;
    font-weight: 700;
}

.main-section h2 {
    font-size: 1.15rem;
    font-weight: 400;
}

.image-carousel {
    width: 80%;
    max-height: 377px;
}

@media only screen and (max-width: 1200px) {
    .main-section {
        margin-top: 6rem;
    }
}

@media only screen and (max-width: 768px) {
    .main-section {
        flex-direction: column-reverse;
        height: auto;
        max-height: none;
        margin-top: 2rem;
    }

    .main-section h1 {
        font-size: 1.6rem;
    }

    .main-section h2 {
        font-size: 1rem;
    }

    .left-column {
        width: 100%;
        min-width: unset;
        align-items: center;
        margin-top: 2rem;
    }

    .right-column {
        width: 100%;
    }

    .main-section h1,
    .main-section h2 {
        margin-left: 0;
        text-align: center;
    }

    .image-carousel {
        height: auto;
        margin: 0 auto;
    }
}
