@import "/src/colors.scss";

.footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: $primary;
}

.column {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.copyright {
    display: flex;
    flex-direction: row;
    align-items: center;
}