@import "/src/colors.scss";

.contact-section {
    display: flex;
    flex-direction: column;
    color: $primary;
    margin-top: 9rem;
}

.title {
    text-align: center;
}

.first-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.paragraph {
    font-size: 1.15rem;
    text-align: center;
}

.second-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 4rem;
}

@media only screen and (max-width: 768px) {
    .second-row {
        flex-direction: column;
        align-items: center;
    }
}
