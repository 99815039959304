@import "/src/colors.scss";

.header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.menu-container {
    display: flex;
    flex-direction: row;
}

.link {
    text-decoration: none;
}

@media only screen and (max-width: 768px) {
    .menu-container {
        display: none;
    }
}
