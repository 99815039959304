@import "/src/colors.scss";

.service-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10rem;
    color: $primary;
}

.first-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 3rem;
    align-items: center;
}

.first-row > .left-column {
    width: 60%;
}

.first-row > .right-column {
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: space-between;
}

.service-section ul {
    font-size: 1.15rem;
    border-radius: 9px;
    padding-left: 1.4rem;
}

.image-carousel {
    width: 80%;
    max-height: 377px;
}

@media only screen and (max-width: 1200px) {
    .service-section {
        margin-top: 4rem;
    }
}

@media only screen and (max-width: 768px) {

    .service-section {
        margin-top: 2rem;
    }

    .first-row {
        flex-direction: column;
    }

    .first-row > .left-column {
        // display: none;
        width: 80%;
    }

    .first-row > .right-column {
        width: 100%;
        margin-top: 1.5rem; 
        align-items: center;
    }

    .service-section ul {
        font-size: 1rem; 
    }

    .image-carousel {
        width: 100%; 
        margin-bottom: 1.5rem; 
    }
}
