@import "/src/colors.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-height: 100vh;
  background-color: $background-color;
}

#background-container {
  position: absolute;
  z-index: -1;

  width: 100vw;
  height: 100vh;
  min-height: 200px;
  max-width: 1920px;
  max-height: 1080px;

  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-color: $background-color;
  background-image: 
    linear-gradient(to right, rgba(255, 255, 255, 0) 90%, $background-color),
    linear-gradient(to left, rgba(255, 255, 255, 0) 90%, $background-color),
    linear-gradient(to bottom, rgba(255, 255, 255, 0) 80%, $background-color), 
    url("../public/background.jpg");

  left: 50%;
  transform: translateX(-50%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
