@import "/src/colors.scss";

button { all: unset; }

.btn {
    width: 153px;
    height: 50px;
    border: 2px solid;
    border-radius: 9px;
    text-align: center;
    margin: 5px;
    cursor: pointer;
    user-select: none;
}

.rounded {
    border-radius: 30px;
}

.primary {
    background-color: $primary;
    border-color: $primary;
    color: $white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.primary-outline {
    border-color: $primary;
    color: $primary;
}

.white-outline {
    border-color: $white;
    color: $white;
}

.primary:hover, .primary-outline:hover, .white-outline:hover {
    background-color: $primary-hover;
    border-color: $primary-hover;
    color: $white;
}

.primary:active, .primary-outline:active, .white-outline:active {
    background-color: $primary-active;
    border-color: $primary;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
